import React from 'react';
import styled from 'styled-components';
import 'focus-visible';
import { useLocalization } from '@plugins/keytrade-localization/useLocalization';
import { IconButton } from '@keytrade/components-button';
import { config } from '@keytrade/functions';
import { Icon } from '@keytrade/components-icon';

import { mediaQuery } from '@/utils/helpers';
import { addSearchParameter } from '@/utils/URLUtils';
import useTranslations from '@/hooks/useTranslations';

const { list: colors } = config.colors;
const { getFont } = config.fonts;

const Form = styled.form`
  position: relative;
  z-index: 2;
`;
type SearchingProps = {
  isSearching?: boolean;
};
const SearchInput = styled.input<SearchingProps>`
  width: 100%;
  flex-grow: 1;
  background: white;
  border: none;
  border-bottom: 1px solid ${colors.BlueFog};
  padding: 1.8rem 5.6rem 1.8rem 1.8rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: ${({ theme }) => getFont(theme, 'regular')};
  color: ${colors.BlueDark};
  &::placeholder {
    color: ${colors.GreyBlue};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0.4rem 0 ${colors.BlueCloudyTransparent};
  }
  ${mediaQuery.lg(`
  margin-bottom: 3.2rem;
  border-radius: 8px;
  border: 1px solid ${colors.BlueFog};
  `)}
  ${({ isSearching }) =>
    isSearching
      ? `
      ${mediaQuery.lg(`
    border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom:0;`)}
  `
      : `
    margin-bottom: 3.2rem;
    border-radius: 8px;
    border: 1px solid ${colors.BlueFog};
    `}
`;
const SearchIcon = styled.div`
  position: absolute;
  right: 1.8rem;
  display: flex;
  top: 1.6rem;
  align-items: center;
  border: none;
  border-radius: 0.3rem;
`;
const IconWrapper = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 1.4rem;
  display: flex;
  align-items: center;
`;
type SearchBoxProps = {
  doSearch: any;
  title: string;
  value: string;
  clearValue: any;
  isSearching?: boolean;
  supportPath: string;
  locale: string;
};
const SearchBox: React.FC<SearchBoxProps> = ({
  doSearch,
  title,
  value,
  clearValue,
  isSearching,
  supportPath,
  locale,
}) => {
  const { t } = useTranslations('search');
  const onClear = () => {
    clearValue();
  };
  const { navigate } = useLocalization();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        navigate(addSearchParameter(supportPath, value), locale);
      }}
    >
      <SearchInput
        type='text'
        isSearching={isSearching}
        placeholder={title}
        aria-label={title}
        onChange={(e) => doSearch(e.target.value)}
        value={value}
      />
      {value !== '' && (
        <IconWrapper>
          <IconButton
            icon={<Icon icon='icn_crossLight' />}
            kind='tertiaryNoBg'
            noSubmit
            size='sm'
            onClick={onClear}
            accessibilityText={t('clear')}
          />
        </IconWrapper>
      )}
      {value === '' && (
        <SearchIcon onClick={onClear}>
          <Icon icon='icn_search' color={colors.BlueSky} />
        </SearchIcon>
      )}
    </Form>
  );
};

export default SearchBox;
