import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import algoliasearch from 'algoliasearch/lite';
import { LoaderSpinner } from '@keytrade/components-loader';

import { mediaQuery } from '@/utils/helpers';
import BlockWrapper from '@/components/BlockWrapper';
import Container from '@/components/Container';
import FAQItem, { FAQItemType } from '@/components/support/FAQItem';
import Title from '@/components/Title';
import Link, { LinkStyle, LinkType } from '@/components/Link';
import useTranslations from '@/hooks/useTranslations';

import { DefaultPaths } from '../../Page';
import SearchBox from './SearchBox';
import SearchResults from './SearchResults';

const { list: colors } = config.colors;

const LinksWrapper = styled.div`
  padding: 0 2rem;
`;

const SupportLinkWrapper = styled.div`
  margin-top: 3.2rem;
`;

export type HelpBlockType = {
  id: string;
  __typename: string;
  title: string;
  faqs: Array<FAQItemType>;
  supportPageLink: LinkType;
  defaultPaths?: DefaultPaths;
  locale: string;
};

type Props = HelpBlockType;
type SearchResultsContainerProps = {
  hasResults: boolean;
};

const SearchResultsContainer = styled.div<SearchResultsContainerProps>`
  ${mediaQuery.lg(`
  top: 5.4rem;
  left: 0;
  right: 0;
  z-index: 10;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  border: 1px solid ${colors.BlueFog};
  
  display:block;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  position:absolute;
  transition: transform 0.4s ease;
  background-color: ${colors.White};
  border-radius: 0 0 8px 8px;
    padding: 0;
  
  `)}
  ${({ hasResults }) =>
    hasResults
      ? `display:block;
    `
      : `display:none;
      `}
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.2rem 3.6rem 7.2rem 0;
  ${mediaQuery.lg(`
  padding:0;
  min-height: 25.4rem;
  `)}
`;
type IsSearchingProps = {
  isSearching: boolean;
};
const SearchContainer = styled.div<IsSearchingProps>`
  ${({ isSearching }) =>
    isSearching
      ? `position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background: white;

  width: 100%;
  height: 100%;
  ${mediaQuery.lg(`
  position: relative;
  z-index: 1;
  width: auto;
  height: auto;
  background: transparent;
  `)}
  `
      : `
  position: relative;
  z-index: 0;
  width: auto;
  height: auto;
  `}
`;
const HelpBlock: React.FC<Props> = ({
  id,
  title,
  faqs,
  supportPageLink,
  defaultPaths,
  locale,
}) => {
  const { t } = useTranslations('search');
  const [isLoading, setLoading] = useState(false);
  const [searchId, setSearchId] = useState(-1);
  const [hits, setHits] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const searchContainerRef = useRef(null);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  );
  const index = client.initIndex(`${process.env.GATSBY_ENVIRONMENT}-${locale}`);
  const supportPath = '/' + supportPageLink?.page?.slug;
  const doSearch = (value: string): void => {
    setSearchValue(value);

    if (value.length > 2) {
      if (searchId !== -1) window.clearTimeout(searchId);
      setLoading(true);
      setSearchOpen(true);
      setSearchId(
        window.setTimeout(() => {
          index.search(value).then(({ hits }) => {
            setHits(hits.splice(0, 5));
            setLoading(false);
          });
        }, 500),
      );
    } else {
      setHits([]);
      setSearchOpen(false);
      searchContainerRef.current.querySelector('input').focus();
    }
  };
  const clearValue = () => {
    doSearch('');
  };
  useEffect(() => {
    const closeSearchIfOutside = (e) => {
      if (!searchContainerRef.current?.contains(e.target)) {
        setSearchOpen(false);
      }
    };

    window.addEventListener('mousedown', closeSearchIfOutside);
    return () => {
      window.removeEventListener('mousedown', closeSearchIfOutside);
    };
  }, [searchContainerRef]);

  return (
    <BlockWrapper id={id} color={'Blue Fog'}>
      <Container narrow={true}>
        <Title level='h2' center={true} size='xl' margin='0 0 4rem'>
          {title}
        </Title>
        <SearchContainer ref={searchContainerRef} isSearching={isSearchOpen}>
          <SearchBox
            doSearch={doSearch}
            isSearching={isSearchOpen}
            clearValue={clearValue}
            value={searchValue}
            title={t('placeholder')}
            supportPath={supportPath}
            locale={locale}
          />
          {isSearchOpen && (
            <SearchResultsContainer
              hasResults={searchValue && searchValue.length > 2}
            >
              {!isLoading && (
                <div>
                  <SearchResults
                    hits={hits}
                    searchValue={searchValue}
                    supportPath={supportPath}
                    closeSearch={() => {
                      setSearchOpen(false);
                    }}
                  />
                </div>
              )}
              {isLoading && (
                <Loader>
                  <LoaderSpinner />
                </Loader>
              )}
            </SearchResultsContainer>
          )}
        </SearchContainer>
        <LinksWrapper>
          {faqs &&
            React.Children.toArray(
              faqs.map((itemQuestion) => {
                return FAQItem({
                  ...itemQuestion,
                  color: 'GreyDark',
                  isSemiBold: true,
                  arrowRight: true,
                  margin: '0 0 3.2rem',
                  defaultPaths,
                });
              }),
            )}
          {supportPageLink && (
            <SupportLinkWrapper>
              <Link {...supportPageLink} linkStyle={LinkStyle.SECONDARY} />
            </SupportLinkWrapper>
          )}
        </LinksWrapper>
      </Container>
    </BlockWrapper>
  );
};

export default HelpBlock;
export const helpBlockFragmentQuery = graphql`
  fragment HelpBlockFragment on ContentfulHelpBlock {
    id
    __typename
    title
    faqs {
      ...FAQItemFragment
    }
    supportPageLink {
      ...LinkFragment
    }
  }
`;
