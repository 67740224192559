import React, { useRef } from 'react';
import styled from 'styled-components';
import sanitizeHtml from 'sanitize-html';
import { config } from '@keytrade/functions';
import { Icon } from '@keytrade/components-icon';

import { getBackgroundColor } from '@/utils/StyleUtils';
import InternalLink from '@/components/InternalLink';
import Title from '@/components/Title';
import { mediaQuery } from '@/utils/helpers';
import { LinkStyle } from '@/components/Link';
import { HitType } from '@/components/search/SearchResults';
import { addSearchParameter } from '@/utils/URLUtils';
import useTranslations from '@/hooks/useTranslations';

import NoResults from '../../search/NoResults';

const { list: colors } = config.colors;

const Wrapper = styled.div`
  overflow-y: auto;
  pointer-events: all;
`;

const PageResult = styled.div`
  em {
    font-style: normal;
    background-color: ${getBackgroundColor('Highlight')};
  }

  a {
    display: block;
    padding: 0.8rem;
    ${mediaQuery.lg(`
      padding: .8rem 1.2rem;
    `)}

    &:focus, &:hover {
      outline: none;
      background-color: ${colors.GreyBackground};
    }
  }
`;

const TitleContent = styled.span`
  display: flex;
  align-items: center;

  > svg {
    flex-shrink: 0;
  }
`;

const MoreWrapper = styled.div`
  margin: 0 0 1.4rem;
  span {
    display: block;
    a {
      padding: 0.8rem 1.6rem !important;
      display: flex;
      &:focus {
        background-color: ${colors.GreyBackground};
      }
    }
  }
`;

const PageHit = (props) => {
  const { hit, closeSearch, index } = props;

  return (
    <PageResult data-index={index}>
      <InternalLink
        to={hit.url}
        onClick={closeSearch}
        linkStyle={LinkStyle.CONTENT}
      >
        <Title lightWeight size='xxs' forceMobileSize='xs' level='h4'>
          <TitleContent>
            <Icon
              icon='icn_longArrowRight'
              color={colors.GreyBlue}
              size='2.4rem'
              margin='0 1.2rem 0 0'
            />
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  hit._highlightResult.title
                    ? hit._highlightResult.title.value
                    : hit.title,
                ),
              }}
            />
          </TitleContent>
        </Title>
      </InternalLink>
    </PageResult>
  );
};

type Props = {
  hits: Array<HitType>;
  searchValue: string;
  supportPath: string;
  closeSearch: () => void;
};

const SearchResults: React.FC<Props> = ({
  hits,
  searchValue,
  supportPath,
  closeSearch,
}) => {
  const wrapperRef = useRef(null);
  const { t } = useTranslations();

  return (
    <Wrapper ref={wrapperRef}>
      {hits.length ? (
        <>
          {React.Children.toArray(
            hits.map((hit, index) => (
              <PageHit
                hit={hit}
                key={hit.objectID}
                closeSearch={closeSearch}
                index={index}
              />
            )),
          )}
          <MoreWrapper data-index={hits.length}>
            <InternalLink
              linkStyle={LinkStyle.SECONDARY}
              to={addSearchParameter(supportPath, searchValue)}
              onClick={closeSearch}
            >
              {t('more')}
            </InternalLink>
          </MoreWrapper>
        </>
      ) : (
        <NoResults />
      )}
    </Wrapper>
  );
};

export default SearchResults;
